import React from "react"
import { Link } from "gatsby"
import { css, Styled } from "theme-ui"

const HeaderLinks = () => (
  <Styled.div
    css={css({
      display: "contents",
    })}
  >
    <Styled.a
      as={Link}
      to="/notes"
      css={css({
        ml: 2,
        mr: 2,
        fontFamily: `heading`,
        fontWeight: `bold`,
        textDecoration: `none`,
        color: `inherit`,
        ":hover": {
          textDecoration: `underline`,
        },
      })}
    >
      /notes
    </Styled.a>
    <Styled.a
      as={Link}
      to="/photos"
      css={css({
        ml: 0,
        mr: 1,
        pr: 10,
        fontFamily: `heading`,
        fontWeight: `bold`,
        textDecoration: `none`,
        color: `inherit`,
        ":hover": {
          textDecoration: `underline`,
        },
      })}
    >
      /photos
    </Styled.a>
    <Styled.a
      as={Link}
      to="/blog"
      css={css({
        ml: 0,
        mr: `auto`,
        pr: 10,
        fontFamily: `heading`,
        fontWeight: `bold`,
        textDecoration: `none`,
        color: `inherit`,
        ":hover": {
          textDecoration: `underline`,
        },
      })}
    >
      /blog
    </Styled.a>
  </Styled.div>
)

export default HeaderLinks
