import React from "react"
import { Link } from "gatsby"
import { css, useColorMode, Styled } from "theme-ui"
import Switch from "gatsby-theme-blog/src/components/switch"
import Bio from "gatsby-theme-blog/src/components/bio"
import sun from "../../../content/assets/sun.png"
import moon from "../../../content/assets/moon3.png"
import HeaderLinks from "./headerLinks"

const rootPath = `${__PATH_PREFIX__}/`

const Title = ({ children, location }) => {
  if (location.pathname === rootPath) {
    return (
      <div>
        <Styled.h1
          css={css({
            my: 0,
            fontSize: 4,
          })}
        >
          <Styled.a
            as={Link}
            css={css({
              color: `inherit`,
              boxShadow: `none`,
              textDecoration: `none`,
            })}
            to={`/`}
          >
            {children}
          </Styled.a>
        </Styled.h1>
      </div>
    )
  } else {
    return (
      <div>
        <Styled.h3
          as="p"
          css={css({
            my: 0,
          })}
        >
          <Styled.a
            as={Link}
            css={css({
              boxShadow: `none`,
              textDecoration: `none`,
              color: `primary`,
            })}
            to={`/`}
          >
            {children}
          </Styled.a>
        </Styled.h3>
      </div>
    )
  }
}

const iconCss = [{ pointerEvents: `none`, margin: 4 }]

const checkedIcon = (
  <img
    alt="moon indicating dark mode"
    src={moon}
    width="16"
    height="16"
    role="presentation"
    css={iconCss}
  />
)

const uncheckedIcon = (
  <img
    alt="sun indicating light mode"
    src={sun}
    width="16"
    height="16"
    role="presentation"
    css={iconCss}
  />
)

export default ({ children, title, ...props }) => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = e => {
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <header>
      <div
        css={css({
          maxWidth: `container`,
          mx: `auto`,
          px: 3,
          pt: 4,
        })}
      >
        <div
          css={css({
            display: `flex`,
            justifyContent: `space-between`,
          })}
        >
          <Title {...props}>{title}</Title>
          {children}
          <Switch
            aria-label="Toggle dark mode"
            checkedIcon={checkedIcon}
            uncheckedIcon={uncheckedIcon}
            checked={isDark}
            onChange={toggleColorMode}
          />
        </div>
        {/* {props.location.pathname === rootPath && <Bio />} */}
      </div>
      <div css={css({ mt: 3, alignItems: `center`, textAlign: 'center', width: '100%' })}>
        {/* <HeaderLinks /> */}
      </div>
    </header>
  )
}
