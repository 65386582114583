/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keywords = [], title, favicon }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <div>
      <link rel="icon" href={"/" + favicon + "/favicon.ico"}></link>
      <link rel="apple-touch-icon" sizes="57x57" href={"../../../" + favicon + "/apple-icon-57x57.png"} />
      <link rel="apple-touch-icon" sizes="60x60" href={"../../../" + favicon + "/apple-icon-60x60.png"} />
      <link rel="apple-touch-icon" sizes="72x72" href={"../../../" + favicon + "/apple-icon-72x72.png"} />
      <link rel="apple-touch-icon" sizes="76x76" href={"../../../" + favicon + "/apple-icon-76x76.png"} />
      <link rel="apple-touch-icon" sizes="114x114" href={"../../../" + favicon + "/apple-icon-114x114.png"} />
      <link rel="apple-touch-icon" sizes="120x120" href={"../../../" + favicon + "/apple-icon-120x120.png"} />
      <link rel="apple-touch-icon" sizes="144x144" href={"../../../" + favicon + "/apple-icon-144x144.png"} />
      <link rel="apple-touch-icon" sizes="152x152" href={"../../../" + favicon + "/apple-icon-152x152.png"} />
      <link rel="apple-touch-icon" sizes="180x180" href={"../../../" + favicon + "/apple-icon-180x180.png"} />
      <link rel="icon" type="image/png" sizes="192x192" href={"../../../" + favicon + "/android-icon-192x192.png"} />
      <link rel="icon" type="image/png" sizes="32x32" href={"../../../" + favicon + "/favicon-32x32.png"} />
      <link rel="icon" type="image/png" sizes="96x96" href={"../../../" + favicon + "/favicon-96x96.png"} />
      <link rel="icon" type="image/png" sizes="16x16" href={"../../../" + favicon + "/favicon-16x16.png"} />
      <link rel="manifest" href={"../../../" + favicon + "/manifest.json"} />
      <meta name="msapplication-TileImage" content={"../../../" + favicon + "/ms-icon-144x144.png"} />

      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s ${site.siteMetadata.title}`}
        link={[
          {
            "rel": "icon",
            "type": "image/png",
            "href": favicon + "/favicon.ico"
          }
        ]}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
              : []
          )
          .concat(meta)}
      />
    </div>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO