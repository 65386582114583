import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from "gatsby";
import { css, Styled } from "theme-ui";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children
}) => <>
    {children}
  </>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h3 {...{
      "id": ""
    }}>{`👋`}</h3>
    <p>{`I’m Pedro, a Solutions Architect @ `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/"
      }}>{`AWS`}</a>{` 🇵🇹🇪🇺🌍`}</p>
    <p>{`I love good `}<a parentName="p" {...{
        "href": "/notes/books"
      }}>{`books`}</a>{`, `}<a parentName="p" {...{
        "href": "/notes/papers"
      }}>{`papers`}</a>{`, hiking and `}<a parentName="p" {...{
        "href": "/photos"
      }}>{`photography`}</a>{`.`}</p>
    <p>{`You can reach me on `}<a parentName="p" {...{
        "href": "https://twitter.com/pedrodc23"
      }}>{`twitter`}</a>{`.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      