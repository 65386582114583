/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Styled, css, Flex } from "theme-ui"
import BioContent from "./bio-content"
import HeaderLinks from "./headerLinks"

const Bio = () => {
  const data = useStaticQuery(bQuery)
  const {
    site: {
      siteMetadata: { author },
    },
    avatar,
  } = data

  return (
    <div>
      <Flex>
        <Styled.div css={css({ mb: 2, mt: 4, alignItems: `center`, width: '100%', mr: [1, 2, 4], ml: [1, 2, 4] })}>
          <BioContent />
        </Styled.div>
      </Flex>
    </div>
  )
}

const bQuery = graphql`
  query BQuery {
    site {
      siteMetadata {
        author
      }
    }
    avatar: file(absolutePath: { regex: "/avatar.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fixed(width: 48, height: 48) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Bio